<template>
    <TableLayout
        :module="module"
        ref="TableLayout"
        :form-initial="form_initial"
        :form-rules="formRules"
        :rights="rights"
        :table-columns="tableColumns"
        v-if="(user && user.rights)"
        initSortField="name"
        initSortOrder="desc"
        :filters="filters"
        addButtonName="Add Сounterparty">

        <template v-slot:form_items="{formdata}">
            <el-form-item label="Name" prop="name">
                <el-input v-model="formdata.name" maxlength="100" minlength="2" />
            </el-form-item>
            <el-form-item label="Jurisdiction" prop="jurisdiction">
                <el-input v-model="formdata.jurisdiction"  />
            </el-form-item>

            <div v-for="(field, index) in formdata.contacts" class="custom_fields-row">
                <el-form-item label="Contacts Field Name" prop="phone">
                    <el-input v-model="field.field_name" />
                </el-form-item>
                <el-form-item label="Contacts Field Value" prop="phone" class="spacer">
                    <el-input v-model="field.field_value" />
                </el-form-item>
                <div class="custom_fields-row_del">
                    <el-button type="text" size="mini" @click="formdata.contacts.splice(index, 1);">
                        <i class="el-icon-close"></i>
                    </el-button>
                </div>
            </div>

            <div>
                <el-button type="text" size="mini" @click="formdata.contacts.push({field_name:'', field_value:''})"><i class="el-icon-plus"></i> contact field</el-button>
            </div>
        </template>

        <template v-slot:col_contacts="{ row, column }">
            <div style="line-height:16px;">
                <div v-for="line in row.contacts">
                    {{line.field_name}}: {{line.field_value}}
                </div>
            </div>
        </template>

    </TableLayout>
</template>

<script>
import { mapState } from 'vuex'
import TableLayout from '@/components/TableLayout'
import tab from '@/mixins/tab'

export default {
    name: 'dict_counterparties',
    mixins: [tab],
    components: { TableLayout },

    data() {
        return {
            module: 'dictionaryCounterparties',
            form_initial: {},
            formRules: {
                name: [
                    { required: true, message: 'this field is required', trigger: 'blur' },
                ]
            },
            tableColumns: [
                {
                    field : 'name',         
                    title : 'Name',           
                    width : 200,
                    params   : {
                        filter : 'input', 
                    },
                },{
                    field : 'jurisdiction', 
                    title : 'Jurisdiction',   
                    width : 200,
                    params   : {
                        filter : 'input', 
                    },
                },{
                    field    : 'contacts', 
                    title    : 'Contacts', 
                    minWidth : 150, 
                    slots    : { default: 'col_contacts' }
                },
            ],
            filters: {
                name    : { value: undefined },
                jurisdiction   : { value: undefined },
            },
            form_initial: {
                contacts: []
            }
        }
    },

    computed: {
        ...mapState({
            user: state => state.app.user,
        }),

        rights(){
            return {
                view : (this.user && this.user.rights) ? this.user.rights['get-portf-settings']   : false,
                edit : (this.user && this.user.rights) ? this.user.rights['edit-portf-settings']  : false,
                add  : (this.user && this.user.rights) ? this.user.rights['add-portf-settings']   : false,
                del  : (this.user && this.user.rights) ? this.user.rights['delete-portf-settings']: false,
            }
        }
    },
}
</script>
